/* global gon */

import "./included_events_component.scss";

import { Controller as BaseController } from "stimulus";
import "instantsearch.css/themes/reset.css";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { configure, hits, menuSelect, pagination } from "instantsearch.js/es/widgets";
import hitsTemplates from "../../../frontend/components/search/hits/templates";
import forcedFacet from "../../../frontend/components/search/filters/widgets/forced_facet";
import dateSelector from "../../../frontend/components/search/filters/widgets/date_selector";
import sortByRadios from "../../../frontend/components/search/filters/widgets/sort_by_radios";
import hitsOnMap from "../../../frontend/components/search/hits/map/hits_on_map";
import addNumbersToItems from "../../../frontend/components/search/hits/map/add_numbers_to_items";
import icon from "../../assets/javascript/utils/icon_helper";

export class Controller extends BaseController {
  static targets = ["dateFilter", "hits", "map", "miniMap", "pagination", "sorting", "tagFilter"];

  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
    this.indexKey = "sortir";
    this.defaultSortKey = "date";
    this.initAlgolia();
    this.initWidgets();
  }

  connect() {
    this.search.on("render", () => {
      this.updateAccessibility();
    });

    this.search.start();
  }

  disconnect() {
    this.search.dispose();
  }

  initAlgolia() {
    const searchClient = algoliasearch(gon.algolia.application_id, gon.algolia.scoped_api_key);

    this.search = instantsearch({
      indexName: this.indexNameForSortKey(this.defaultSortKey),
      numberLocale: this.currentLocale,
      searchClient,
    });
  }

  initWidgets() {
    let widgets = [];

    widgets.push(
      configure({
        hitsPerPage: 20,
      })
    );

    if (gon.algolia.indexes[this.indexKey].distinct) {
      widgets.push(
        configure({
          distinct: true,
        })
      );
    }

    widgets.push(
      forcedFacet({
        attribute: "parent_ids",
        value: this.parentId,
      })
    );

    const sortableBy = gon.algolia.indexes[this.indexKey].sortable_by;
    if (sortableBy) {
      widgets.push(
        sortByRadios({
          container: this.sortingTarget,
          defaultItemKey: this.defaultSortKey,
          items: sortableBy.map((key) => ({
            key: key,
            label: key,
            index: this.indexNameForSortKey(key),
          })),
        })
      );
    }

    widgets.push(
      dateSelector({
        attribute: "date",
        container: this.dateFilterTarget,
      })
    );

    widgets.push(
      menuSelect({
        container: this.tagFilterTarget,
        attribute: "tags.name",
        limit: 500,
        templates: {
          defaultOption: "Tous les tags",
          item: "{{label}}",
        },
      })
    );

    widgets.push(
      hits({
        transformItems: addNumbersToItems,
        container: this.hitsTarget,
        templates: hitsTemplates.includedEvents,
      })
    );

    widgets.push(
      hitsOnMap({
        container: this.isSmallAndMedium ? this.miniMapTarget : this.mapTarget,
      })
    );

    widgets.push(
      pagination({
        container: this.paginationTarget,
        padding: 2,
        scrollTo: this.element,
        showFirst: false,
        showLast: false,
        templates: {
          previous: icon("arrow-left"),
          next: icon("arrow-right"),
        },
      })
    );

    this.search.addWidgets(widgets);
  }

  updateAccessibility() {
    // Update pagination links
    const paginationLinks = [...document.querySelectorAll(".ais-Pagination-item--page > a")];
    if (paginationLinks.length > 0) {
      paginationLinks.forEach((link) => {
        link.removeAttribute("aria-current");
        link.removeAttribute("aria-label");
      });
    }

    const previousPageLink = document.querySelector(".ais-Pagination-item--previousPage > a");
    if (previousPageLink) {
      previousPageLink.setAttribute("aria-label", "Page précédente");
    }

    const nextPageLink = document.querySelector(".ais-Pagination-item--nextPage > a");
    if (nextPageLink) {
      nextPageLink.setAttribute("aria-label", "Page suivante");
    }

    const selectedPageLink = document.querySelector(".ais-Pagination-item--selected > a");
    if (selectedPageLink) {
      selectedPageLink.setAttribute("aria-current", "page");
      selectedPageLink.setAttribute("aria-label", `${selectedPageLink.innerText} page active`);
    }
  }

  indexNameForSortKey(key) {
    let suffix = "";

    if (key) {
      // N.B. inside included events component sorting by date is ranked by event weight first
      // then we use its sepcific replica `_sort_by_weight`
      // source: https://github.com/parisnumerique/paris-fr-v3/issues/5253
      suffix += `_sort_by_${key === "date" ? "weight" : key}`;
    }

    return this.indexName + suffix;
  }

  enterHit(e) {
    const hitNumber = e.target.dataset.hitNumber;
    const marker = this.getMapMarker(hitNumber);
    if (!marker) return;
    marker.classList.add("is-active");
  }

  leaveHit(e) {
    const hitNumber = e.target.dataset.hitNumber;
    const marker = this.getMapMarker(hitNumber);
    if (!marker) return;
    marker.classList.remove("is-active");
  }

  getMapMarker(hitNumber) {
    return document.querySelector(`.leaflet-marker-icon.paris-search-hits-item-number[data-hit-number="${hitNumber}"]`);
  }

  get parentId() {
    return this.element.getAttribute("data-parent-id");
  }

  get indexName() {
    return gon.algolia.indexes[this.indexKey].name;
  }

  get currentLocale() {
    return document.documentElement.lang;
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }
}
