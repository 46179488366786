import "./timetables_component.scss";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = [
    "districts",
    "name",
    "content",
    "tableHeader",
    "tableBody",
    "next",
    "prev",
    "controls",
    "mainContainer",
    "contentMobile",
    "tableHeaderMobile",
    "tableBodyMobile",
    "nextMobile",
    "prevMobile",
    "controlsMobile",
    "mainContainerMobile",
  ];

  connect() {
    const ctrl = document.querySelector(".places--timetables-ctrl-buttons > svg");
    ctrl.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
    this._initTHead();
    this._initListeners();
    this.actualPart = 0;
    this.filter();
  }

  filter() {
    const select = this.districtsTarget;
    const id = select.options[select.selectedIndex].value;

    this.filterById(id);
  }

  filterById(id) {
    const target = this.isMobile ? this.contentMobileTarget : this.contentTarget;
    if (id === "") {
      target.querySelectorAll("tbody tr").forEach((item) => {
        item.style.display = "table-row";
      });
    } else {
      target.querySelectorAll("tbody tr").forEach((item) => {
        const currentDistrict = item.getAttribute("data-arr") === id;

        item.style.display = currentDistrict ? "table-row" : "none";
      });
    }
  }

  onInput(e) {
    const value = e.target.value.toLowerCase();
    const target = this.isMobile ? this.contentMobileTarget : this.contentTarget;

    document.querySelectorAll(".places--timetables-select-object option").forEach((item) => {
      if (item.value === "all") {
        item.setAttribute("selected", "selected");
      } else {
        item.removeAttribute("selected");
      }
    });

    if (value === "") {
      target.querySelectorAll("tbody tr").forEach((item) => {
        item.style.display = "table-row";
      });
    } else {
      target.querySelectorAll("tbody tr").forEach((item) => {
        item.style.display = "none";
        const name = item.getAttribute("data-name").toLowerCase();
        if (name.includes(value)) {
          item.style.display = "table-row";
        }
      });
    }
  }

  onChangeWeek(e) {
    const nextOrPrev = e.target.classList.contains("next");
    const contentToDealWith = this.isMobile ? this.contentMobileTarget : this.contentTarget;
    const nextTarget = this.isMobile ? this.nextMobileTarget : this.nextTarget;
    const prevTarget = this.isMobile ? this.prevMobileTarget : this.prevTarget;

    if (this.isMobile) {
      this._displayPart(nextOrPrev);
      //
    } else {
      contentToDealWith.querySelectorAll(".prev-week").forEach((item) => {
        const containsNext = e.target.classList.contains("next");
        this._togglecontrol(containsNext, nextTarget, prevTarget);
        item.style.display = containsNext ? "none" : "table-cell";
      });

      contentToDealWith.querySelectorAll(".next-week").forEach((item) => {
        const containsPrev = e.target.classList.contains("prev");
        this._togglecontrol(containsPrev, prevTarget, nextTarget);
        item.style.display = e.target.classList.contains("prev") ? "none" : "table-cell";
      });
    }

    this._fixSize();
  }

  // private
  _initTHead() {
    this._checkViewport();
    const body = document.body;
    const html = document.documentElement;
    body.scrollTop = 0;
    html.scrollTop = 0;
    this._fixHeader();
    this.max = 14;
  }

  _initListeners() {
    window.addEventListener("scroll", () => {
      this._fixHeader();
    });

    const resizeObserver = new ResizeObserver(() => this._onResize());
    resizeObserver.observe(this.mainContainerTarget);
  }

  _onResize() {
    this._checkViewport();
    this._fixHeader();
  }

  _checkViewport() {
    this.isMobile = false;
    if (getComputedStyle(this.mainContainerMobileTarget, null).display === "block") {
      this.isMobile = true;
    }
  }

  _fixHeader() {
    const theadToDealWith = this.isMobile ? this.tableHeaderMobileTarget : this.tableHeaderTarget;
    const contentToDealWith = this.isMobile ? this.contentMobileTarget : this.contentTarget;
    const tbodyToDealWith = this.isMobile ? this.tableBodyMobileTarget : this.tableBodyTarget;

    const navbarHeight = document.querySelector(".paris-header").clientHeight;
    const bounding = contentToDealWith.getBoundingClientRect();
    const heightThead = theadToDealWith.clientHeight;
    const widthThead = theadToDealWith.clientWidth;
    const boundingThead = theadToDealWith.getBoundingClientRect();
    const posLimit = bounding.top - heightThead;

    if (posLimit <= 0) {
      theadToDealWith.style.top = `${navbarHeight}px`;
      tbodyToDealWith.style.transform = `translateY(${navbarHeight}px)`;
      theadToDealWith.style.width = `${widthThead}px`;
      theadToDealWith.style.left = `${boundingThead.left}px`;
      theadToDealWith.classList.add("fixed-header");

      if (this.isMobile) {
        this._fixControls(`${navbarHeight}px`, "fixed");
        this._fixHeaderOnBottom();
      } else {
        this._fixControls(`${navbarHeight}px`, "fixed");
      }
    } else {
      tbodyToDealWith.style.transform = "unset";
      this._unFixThead(theadToDealWith);
      this._fixControls(0, "absolute");
    }

    console.log("widthThead", widthThead);
    this._fixSize();
  }

  _unFixThead(theadToDealWith) {
    theadToDealWith.classList.remove("fixed-header");
    theadToDealWith.style.width = "unset";
    this._fixControls(0, "absolute");
  }

  _fixHeaderOnBottom() {
    const theadToDealWith = this.isMobile ? this.tableHeaderMobileTarget : this.tableHeaderTarget;
    const heightThead = theadToDealWith.clientHeight;
    const footer = document.querySelector("footer");
    const limit = footer.getBoundingClientRect().top - heightThead;
    if (limit <= 0) {
      this._unFixThead(theadToDealWith);
    }
  }

  _fixControls(top, pos) {
    const ctrlToDealWith = this.isMobile ? this.controlsMobileTarget : this.controlsTarget;
    ctrlToDealWith.style.top = top;
    ctrlToDealWith.style.left = this.isMobile ? "50%" : "unset";
    ctrlToDealWith.style.position = pos;
  }

  _fixSize() {
    const theadToDealWith = this.isMobile ? this.tableHeaderMobileTarget : this.tableHeaderTarget;
    const tbodyToDealWith = this.isMobile ? this.tableBodyMobileTarget : this.tableBodyTarget;
    const ctrlToDealWith = this.isMobile ? this.controlsMobileTarget : this.controlsTarget;
    const theadWidth = theadToDealWith.clientWidth;

    const ths = theadToDealWith.querySelectorAll("th");
    const tds = tbodyToDealWith.querySelectorAll("td");
    const visibleTds = this._getVisibleTDS(tds);

    const firstTh = ths[0];
    const firstTd = visibleTds[0];
    const standardTd = visibleTds[1];
    const standardSize = this.isMobile ? this.contentMobileTarget.clientWidth / 2 : standardTd.clientWidth;
    const firstSize = firstTd.clientWidth;
    
    ctrlToDealWith.style.width = this.isMobile ? `${standardSize}px` : `${theadWidth}px`;

    this._formatCols(ths, standardSize);
    this._formatCols(tds, standardSize);
    firstTh.style.width = `${firstSize}px`;
  }

  _getVisibleTDS(tds) {
    return [...tds].filter((t) => {
      return window.getComputedStyle(t).display !== "none";
    });
  }

  _formatCols(els, size) {
    els.forEach((el) => {
      el.style.width = `${size}px`;
    });
  }

  _togglecontrol(bool, el, el2) {
    if (bool) {
      el.classList.add("hidden");
      el2.classList.remove("hidden");
    } else {
      el.classList.remove("hidden");
      el2.classList.add("hidden");
    }
  }

  _displayPart(nextOrPrev) {
    this._actualizePart(nextOrPrev);
    const parts = document.querySelectorAll(".parts");
    const classPart = `part-${this.actualPart}`;
    parts.forEach((item) => {
      const contains = item.classList.contains(classPart);
      item.style.display = contains ? "table-cell" : "none";
    });
    this._shouldDisplayCtrls(this.actualPart);
  }

  _toggleHideControl(ctrl, bool) {
    if (bool) {
      ctrl.classList.add("hidden");
    } else {
      ctrl.classList.remove("hidden");
    }
  }

  _shouldDisplayCtrls(part) {
    if (part === this.max) {
      this._toggleHideControl(this.nextMobileTarget, true);
    } else {
      this._toggleHideControl(this.nextMobileTarget, false);
    }

    if (part > 0) {
      this._toggleHideControl(this.prevMobileTarget, false);
    } else {
      this._toggleHideControl(this.prevMobileTarget, true);
    }
  }

  _actualizePart(nextOrPrev) {
    if (nextOrPrev) {
      if (this.actualPart === this.max) {
        return false;
      }

      this.actualPart++;
    } else {
      if (this.actualPart === 0) {
        return false;
      }

      this.actualPart--;
    }
  }
}
