import "./list_component.scss";
import { decodeMails } from "util/mailsHelper";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["liContainer"];

  connect() {
    decodeMails(this.hasLiContainerTarget, this.liContainerTarget);
    if (this.hasLiContainerTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.liContainerTarget);
    }
  }
}
