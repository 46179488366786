import "./place_component.scss";
import { Controller as BaseController } from "stimulus";

import L from "leaflet";

// TODO: implement dynamic import
import MARKER_OPEN from "images/ico-nef-open.svg";
import MARKER_CLOSED from "images/ico-nef-closed.svg";
import MARKER_UNKNOWN from "images/ico-nef-unknown.svg";

const DEFAULT_ICON = {
  iconSize: [54, 62],
  iconAnchor: [26, 62]
};

const ICON_UNKNOWN = L.icon({
  iconUrl: MARKER_UNKNOWN,
  ...DEFAULT_ICON
});

const ICON_OPEN = L.icon({
  iconUrl: MARKER_OPEN,
  ...DEFAULT_ICON
});

const ICON_CLOSED = L.icon({
  iconUrl: MARKER_CLOSED,
  ...DEFAULT_ICON
});

const DEFAULT_ZOOM = 14;
const DEFAULT_LAYER_ATTRIBUTION = 'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
const TILE_LAYER = "https://cdn.paris.fr/leaflet/paris/{z}/{x}/{y}.png";
const TILE_LAYER_MIN_ZOOM = 11;

// Override leaflet default icon marker
// Ref: https://leafletjs.com/reference-1.5.0.html#icon-default
export class Controller extends BaseController {
  static targets = ["map"];

  initialize() {
    this._map = null;
    this._options = {
      zoom: DEFAULT_ZOOM,
      scrollWheelZoom: false,
      zoomControl: true
    };
  }

  connect() {
    this.initMap();
  }

  initMap() {
    if (!this.hasMapTarget) {
      return;
    }

    this._map = null;
    // Read Attributes
    this._latLng = L.latLng(this.data.get("latlng").split(","));
    this._status = this.data.get("status") || null;

    if (this._status === null) {
      L.Marker.prototype.options.icon = ICON_UNKNOWN;
    } else {
      L.Marker.prototype.options.icon = this._status === "open" ? ICON_OPEN : ICON_CLOSED;
    }

    // Create Map
    this._map = L.map(this.mapTarget, this._options);

    // Assign TileLayer
    L.tileLayer(TILE_LAYER, {
      minZoom: TILE_LAYER_MIN_ZOOM,
      attribution: DEFAULT_LAYER_ATTRIBUTION
    }).addTo(this._map);

    // Set View
    this._map.setView(this._latLng);

    // Add Marker
    L.marker(this._latLng).addTo(this._map);
  }
}
