import "./districts_component.scss";

import Tablist from "@accede-web/tablist";
import { decodeMails } from "../../../assets/javascript/utils/mailsHelper";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["text", "defaultPanel"];

  initialize() {
    this.tablist = null;
  }

  connect() {
    // setup tablist
    const list = this.element.querySelector('[role="tablist"]');
    this.tablist = new Tablist(list);
    this.tablist.mount();

    if (this.tablist._tablist.tabPanels.length > 1) this._hideDefaultOpenedTab();

    // update hash in url when panel is displayed
    this.tablist.on("show", (tab, _panel) => {
      const hash = tab.getAttribute("aria-controls");
      window.history.pushState({}, hash, `#${hash}`);

      // remove default panel
      if (this.hasDefaultPanelTarget) this.defaultPanelTarget.remove();
    });

    this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");

    // decode emails
    this.textTargets.forEach((text) => {
      decodeMails(true, text, false, false);
      this.anchorController.checkForMultipleLinktags(text);
    });
  }

  displayDistrict(hash) {
    if (!this.tablist || !hash) return;

    const tabElem = this.element.querySelector(`[aria-controls="${hash}"`);
    if (tabElem) {
      this.tablist.unmount();
      tabElem.setAttribute("data-open", true);
      this.tablist.mount();
    }
  }

  // PRIVATE

  _hideDefaultOpenedTab() {
    const { tab, tabPanel } = this.tablist.current;

    // reset tab and panel visibility, without changing tab tabindex to allow focus with keyboard
    tab.removeAttribute("aria-selected");
    tabPanel.setAttribute("aria-hidden", "true");

    // reset internal opened index value
    delete this.tablist._tablist.openedIndex;
  }
}
