import { decodeMails } from "../../../assets/javascript/utils/mailsHelper";
import "./text_component.scss";

import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["textContainer"];

  connect() {
    decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
    if (this.hasTextContainerTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.textContainerTarget);
    }
  }
}
