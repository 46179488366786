import { Controller as BaseController } from "stimulus";

import Accordion from "@accede-web/accordion";
import { decodeMails } from "../../../../frontend/util/mailsHelper";

import "./table_component.scss";

export class Controller extends BaseController {
  static targets = ["textContainer", "subtitle"];

  initialize() {
    this.accordion = null;
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
  }

  connect() {
    this.initAccordion();
    this.mediaQueryList.addListener(() => this.initAccordion());
    if (this.hasTextContainerTarget) {
      decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.textContainerTarget);
    }
  }

  initAccordion() {
    if (this.isSmallAndMedium && !this.accordion) {
      this.accordion = new Accordion(this.element);
      this.accordion.mount();
    } else if (this.accordion) {
      this.accordion.unmount();
      this.accordion = null;
    }
  }

  toggleSubtitle(e) {
    e.currentTarget.previousElementSibling
      .querySelector(".paris-table-alt-heading-subtitle")
      .classList.toggle("is-hidden");
  }

  switchButtonLabels(e) {
    const button = e.target;
    const currentLabel = button.getAttribute("aria-label");
    const altLabel = button.getAttribute("data-alt-aria-label");
    button.setAttribute("data-alt-aria-label", currentLabel);
    button.setAttribute("aria-label", altLabel);
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }
}
