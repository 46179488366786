import "./section_component.scss";

import Flickity from "flickity";
import "flickity/dist/flickity.css";
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ["container"];

  initialize() {
    this.flkty = null;
    this.timeout = false;
    this.delay = 250;
  }

  connect() {
    this.handleFlickity();
    window.addEventListener("resize", () => this.handleResize());
    if (this.hasContainerTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.containerTarget);
    }
  }

  handleResize() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.handleFlickity(), this.delay);
  }

  handleFlickity() {
    if (this.flkty) {
      this.flkty.destroy();
    }

    if (!window.matchMedia("(max-width:800px)").matches) return;

    this.flkty = new Flickity(this.cardsTarget, {
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      accessibility: true,
    });
  }
}
